import "./App.css";
import styled from "@emotion/styled";

const TitleContainer = styled.div({
  display: "flex",
  width: "100vw",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
});

function App() {
  return (
    <TitleContainer data-testid={"frontpage-title"}>
      <h1>Danksongs</h1>
    </TitleContainer>
  );
}

export default App;
